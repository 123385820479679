.gameStartContainer{
    display: flex;
    width: 100vw;
    padding-bottom: 56.25%;
    height: 0;
    position: relative;
    background-color: #2B3B76;
    overflow: hidden;

    & video {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    & .wrapper{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        & .charactersWrapper{
            flex: 3;
            display: flex;
            flex-direction: row;
            justify-content: center;

            & .signsWrapper{
                display: flex;
                position: absolute;
                align-self: flex-start;
                justify-content: center;
                width: 100%;
                height: 60%;
                flex-direction: row;
                transition: top 5s;
                transition-timing-function: linear;

                & .sign{
                    container-type: size;
                    position: relative;
                    height: 45%;
                    width: 12.5%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;

                    & div{
                        color: #2B3B76;
                        margin-bottom: 9%;
                        font-size: 8cqh;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 40%;
                    }
                }
            }

            & .characterWrapper{
                display: flex;
                height: 100%;
                width: 12.5%;
                justify-content: center;
                align-items: flex-end;


                & img{
                    min-width: 135%;
                    height: 60%;
                }
            }
        }
    }
}
