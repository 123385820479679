.lobbyContainer{
  width: 100vw;
  padding-bottom: 56.25%;
  height: 0;
  position: relative;
  overflow: hidden;

  & .roomInfo{
    display: flex;
    position: absolute;
    background-color: #E4C67F;
    align-items: center;
    padding: 0 2% 0 0;
    border-radius: 0 0 5vh 0;
    box-shadow: 0 0 5vh #E4C67F;
    color: #2B3B76;
    font-size: 4vmin;
  }
  
  & .viking{
    position: absolute;
    height: 46%;
    width: 27%;
    right: 6.1%;
    top: 19.3%;
  }
  
  & .catWoman{
    position: absolute;
    height: 46%;
    width: 27%;
    right: 30%;
    bottom: 10%;
    z-index: 3;
  }

  & .cat{
    position: absolute;
    height: 46%;
    width: 27%;
    left: 31.2%;
    top: 8%;
  }

  & .astronaut{
    position: absolute;
    height: 45%;
    width: 27%;
    right: 28%;
    top: 27.1%;
  }

  & .punk{
    position: absolute;
    height: 45%;
    width: 27%;
    left: 23.1%;
    top: 15.5%;
  }

  & .alien{
    position: absolute;
    height: 45%;
    width: 27%;
    right: 1.3%;
    bottom: 8.5%;
    z-index: 2,
  }

  & .death{
    position: absolute;
    height: 45%;
    width: 27%;
    left: 5%;
    bottom: 16.5%;
  }

  & .physicist{
    position: absolute;
    height: 45%;
    width: 27%;
    right: 32.2%;
    top: 9%;
  }
}
