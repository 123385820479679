.endGameContainer{
    display: flex;
    width: 100vw;
    padding-bottom: 56.25%;
    height: 0;
    position: relative;
    background-color: #2B3B76;
    overflow: hidden;

    & img{
        position: absolute;
        flex: 1;
        width: 100%;
    }

    & .endGameInfosWrapper{
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        & .topWrapper{
            flex-direction: column;
            display: flex;
            flex: 4;
            box-sizing: border-box;
            justify-content: center;

            & .winnerWrapper{
                overflow: hidden;
                position: relative;
                container-type: size;
                width: 33%;
                height: 33%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-self: flex-end;

                & .firstText{
                    top: 0;
                    left: 5%;
                    position: absolute;
                    font-size: 34cqh;
                    color: #E4C67F;
                    -webkit-text-stroke: 1.7cqh #573318;
                }

                & .winnerName{
                    margin-top: 15cqh;
                    text-align: center;
                    font-size: 20cqh;
                }

                & .secondText{
                    overflow: hidden;
                    bottom: -2%;
                    right: 5%;
                    position: absolute;
                    font-size: 21cqh;
                    color: #E4C67F;
                    -webkit-text-stroke: 0.8cqh #573318;
                }
            }

            & .winnerScore{
                align-self: flex-end;
                color: #5478D0;
                font-size: 3cqh;
                margin-right: 20%;
            }
        }

        & .bottomWrapper{
            display: flex;
            flex: 3;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            & .playerWrapper{
                position: relative;
                display: flex;
                width: 14%;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                & img{
                    position: relative;
                    width: 70%;
                }
                
                & .star{
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    left: 5%;
                    top: -5%;
                    height: 20%;
                    width: 20%;
                    font-size: 3vh;
                    color: #a26941;
                    text-align: center;
                    justify-content: center;
                }

                & .playerText{
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    width: 100%;
                    font-size: 4vh;
                    color: #5478D0;
                }
            }
        }
    }
}
