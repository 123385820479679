.gameContainer{
    display: flex;
    width: 100vw;
    padding-bottom: 56.25%;
    height: 0;
    position: relative;
    background-color: black;
    overflow: hidden;

    & img{
        position: absolute;
        flex: 1;
        width: 100%;
    }

    & .wrapper{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        & .charactersWrapper{
            flex: 3;

            & .timer{
                position: absolute;
                z-index: 1;
                top: 1%;
                right: 1%;
                width: 10%;
            }

            & .charactersRow{
                flex: 1;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: center;

                & .characterInfoWrapper{
                    position: relative;
                    display: flex;
                    width: 12.5%;
                    height: 100%;
                    justify-content: center;
                    align-items: flex-end;

                    & .sign{
                        container-type: size;
                        position: absolute;
                        display: flex;
                        height: 45%;
                        width: 50%;
                        justify-content: center;
                        align-items: flex-end;
                        top: 0;

                        & div{
                            max-width: 80%;
                            color: #2B3B76;
                            font-size: 8cqh;
                            padding-bottom: 2vh;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    & .characterWrapper{
                        position: absolute;
                        width: 160%;
                        height: 60%;
                        display: flex;
                        justify-content: center;
                        align-items: end;
                    }
                }
            }
        }

        & .questionWrapper{
            flex: 2;
            box-sizing: border-box;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            & .questionInsert{
                position: absolute;
                display: flex;
                top: -23%;
            }

            & .questionInsertText{
                display: flex;
                position: absolute;
                align-self: center;
                top: -16%;
                width: 60%;
                height: 25%;
                text-align: center;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 3.5vh;
                overflow: hidden;
            }

            & .topicSelection{
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
                font-size: 4vh;
                color: #5478D0;
            }

            & .grid{
                width: 100%;
                height: 80%;
                align-self: flex-end;
                display: grid;
                justify-content: center;
                grid-gap: 0;

                & .userShouldAnswerText{
                    align-self: center;
                    width: 100%;
                    font-size: 3.5vh;
                    color: #5478D0;
                    text-align: center;
                }

                & .buttonWrapper{
                    position: relative;
                    display: flex;

                    & .characterAnswer{
                        position: absolute;
                        display: flex;
                        width: 5cqh;
                        height: 5cqh;
                    }

                    & .button{
                        flex: 1;
                        display: flex;
                        color: white;
                        align-items: center;
                        justify-content: center;
                        font-size: 3.5vh;
                    }
                }
            }
        }
    }
}
