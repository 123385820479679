.connectionContainer{
    width: 100vw;
    height: 100vh;
    background-color: #2B3B76;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 6vh;
    font-weight: bold;
    color: #E4C67F;

    & input {
        margin: 2vh 0;
        background-color: #2B3B76;
        outline: none;
        border: solid #E4C67F 2px;
        border-radius: 20px;
        font-size: 5vh;
        max-width: 20vh;
        text-align: center;
        color: #E4C67F;
        padding: 1vh 0;
        font-family: "Acme-Regular";
    }
}

.error{
    height: 2vh;
    color: red;
    font-size: 2vh;
    font-weight: normal;
}

.buttonWrapper {
    margin-top: 10vh;
    height: 15vh;
    & button {
        background-color: #E4C67F;
        outline: none;
        border: none;
        border-radius: 20px;
        font-size: 3vh;
        text-align: center;
        color: #2B3B76;
        font-weight: bold;
        padding: 1vh 3vh;
        font-family: "Acme-Regular";
    }

    & button:hover{
        background-color: #E4C67FCF;
    }

    & button:active{
        background-color: #E4C67F5F;
    }
}