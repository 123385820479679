@font-face {
  font-family: "Acme-Regular";   /*Can be any text*/
  src: local("Acme"),
    url("../fonts/Acme-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'Acme-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  display: flex;
}

